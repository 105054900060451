import { Controller } from '@hotwired/stimulus';
import { agencyFees } from '../../shared/helpers/agencyFeesHelper';

export default class extends Controller {
  static targets = ['mandatePrice', 'warning', 'noWarning', 'sendMandate', 'premiumPack', 'agencyFees', 'exclusivity', 'buyerFees', 'mandateFeesPercentage']
  static values = {
    parisRegion: Boolean,
    parkingKind: Boolean,
    agencyFees: Number,
    liquidSale: Boolean,
    leadAgent: Boolean,
    estimationHigh: Number
  }

  connect() {
    this.displayWarning();

    if (!this.leadAgentValue) this.buyerFeesTarget.onclick = (e) => { e.preventDefault(); }
    this.premiumPackTarget.checked ? this.disallowPremium() : this.checkPremium();
    if (!this.agencyFeesValue) this.computeFees();
    if (this.agencyFeesValue) this.computeFeesPercentage();
  }

  displayWarning() {
    const mandatePrice = parseInt(this.mandatePriceTarget.value.replace(/\s+/g, ''))
    const delta = (mandatePrice - this.estimationHighValue) / this.estimationHighValue * 100

    if(!this.leadAgentValue && !this.parkingKindValue) {
      if (isNaN(delta)) {
        this.noWarningTarget.classList.add('hidden')
        this.warningTarget.classList.add('hidden')
        this.sendMandateTarget.disabled = true
      } else if (delta < 5 || this.liquidSaleValue) {
        this.noWarningTarget.classList.remove('hidden')
        this.warningTarget.classList.add('hidden')
        this.sendMandateTarget.disabled = false
      } else if (delta >= 5) {
        this.warningTarget.classList.remove('hidden')
        this.noWarningTarget.classList.add('hidden')
        this.sendMandateTarget.disabled = true
      }
    }
  }

  computeFees() {
    this.checkPremium();

    const mandatePrice = parseInt(this.mandatePriceTarget.value.replace(/\s+/g, '')) || 0;
    const noExclusivity = this.exclusivityTargets.filter(target => target.value == 'false')[0];

    this.agencyFeesTarget.value = this.parkingKindValue ? '2 500' : agencyFees(
      this.parisRegionValue,
      mandatePrice,
      noExclusivity.checked
    ).toLocaleString('fr-FR');

    this.computeFeesPercentage()
  }

  computeFeesPercentage() {
    const mandatePrice = parseInt(this.mandatePriceTarget.value.replace(/\s+/g, '')) || 0;
    const fees = parseInt(this.agencyFeesTarget.value.replace(/\s+/g, ''))
    const percentage = (fees/mandatePrice)*100

    if (typeof percentage === 'number' && isFinite(percentage)) {
      this.mandateFeesPercentageTarget.innerText = `${Math.round(percentage*100)/100}%`
    } else {
      this.mandateFeesPercentageTarget.innerText = ''
    }
  }

  checkPremium() {
    const mandatePrice = parseInt(this.mandatePriceTarget.value.replace(/\s+/g, ''));

    if ((!this.parisRegionValue && mandatePrice > process.env.PREMIUM_START_VALUE_REGIONS) || mandatePrice > process.env.PREMIUM_START_VALUE_PARIS) {
      this.premiumPackTarget.checked = true
      this.disallowPremium();
    } else {
      this.premiumPackTarget.checked = false
      this.allowPremium();
    }
  }

  disallowPremium() {
    if (!this.leadAgentValue) {
      this.premiumPackTarget.classList.add('not-allowed-cursor');
      this.premiumPackTarget.onclick = (e) => { e.preventDefault(); }
    }
  }

  allowPremium() {
    if (!this.leadAgentValue) {
      this.premiumPackTarget.classList.remove('not-allowed-cursor');
      this.premiumPackTarget.onclick = null;
    }
  }
}
